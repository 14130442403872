import * as React from "react";
import * as Styles from "./index.module.sass";
import { Link } from "gatsby";
import ReactVideo from "../components/reactvideo";
import useWindowSize from "../utils/useWindowSize";
import Seo from "../components/seo";
import Logo from "./Logo.svg";

const IndexPage = () => {
  const { ar } = useWindowSize();
  return (
    <>
      <Seo
        title={`Главная`}
        description={`Видеоролики, графика и 3D визуализации для любых задач бизнеса и не только.`}
      />
      <div>
        <div className={`react-player-wrapper ${Styles.videowrap}`}>
          <div className={`is-overlay ${Styles.videowrap}`}></div>
          <ReactVideo
            width="100%"
            height="100%"
            playing={true}
            playsinline
            loop={true}
            muted={true}
            video={
              ar > 1
                ? `https://cdn.mustbefamily.com/main.mp4`
                : `https://cdn.mustbefamily.com/main_m.mp4`
            }
            poster={
              ar > 1
                ? `https://cdn.mustbefamily.com/main.jpg`
                : `https://cdn.mustbefamily.com/main_m.jpg`
            }
            className={`${Styles.video}`}
            outerclassName={`${Styles.videowrap}`}
            alt={`Must Be Family шоурил`}
          />
        </div>
      </div>
      <div className={`${Styles.center}`}>
        <img src={Logo} alt="Must be family logo" />
      </div>
      <div className={`${Styles.center}`}>
        <div style={{ height: "60vh" }}></div>
        <h2>Мы создаём визуальный контент</h2>
        <Link to={`/video`}>Смотреть</Link>
      </div>
    </>
  );
};

export default IndexPage;
